<template>
  <el-container>
    <el-aside class="sidebar-container" width="auto">
      <div class="logo-sider">
        <span>LOGO</span>
      </div>

      <el-menu
        router
        @select="handleSelect"
        default-active="this.$router.path"
        :collapse="isCollapse"
        :class="{ myMenu: isActive }"
        text-color="#fff"
        active-text-color="#ffd04b"
        background-color="#545c64"
      >
        <el-menu-item index="/">
          <i class="el-icon-house"></i>
          <span>ホーム</span>
        </el-menu-item>
        <el-menu-item index="/contract">
          <i class="el-icon-document"></i>
          <span>契約管理</span>
        </el-menu-item>
        <el-menu-item index="/about">
          <i class="el-icon-help"></i>
          <span>について</span>
        </el-menu-item>
      </el-menu>
    </el-aside>

    <el-container>
      <el-header>
        <div class="box_bgd" @click="changeCollapse">
          <i class="el-icon-s-fold" v-show="block"></i>
          <i class="el-icon-s-unfold" v-show="toggle"></i>
        </div>
      </el-header>
      <el-main> <router-view v-if="isRouterAlive" /></el-main>
    </el-container>
  </el-container>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      isCollapse: false,
      toggle: false,
      block: true,
    };
  },
  created() {},
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    changeCollapse() {
      this.isCollapse = !this.isCollapse;
      this.toggle = !this.toggle;
      this.block = !this.block;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
  background-color: #eaf0f3;
}
.el-container {
  width: 100%;
  margin: 0;
}
.sidebar-container {
  background-color: #545c64;
}

.sidebar-container .el-menu {
  border-right: none;
}
.logo-sider {
  height: 50px;
  color: #eaf0f3;
  margin: 0px 0px;
}
.el-main {
  height: calc(100vh - 4rem);
}

.box_bgd {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 54px;
}

.box_bgd i {
  font-size: 20px;
}
.box_bgd:hover {
  background-color: rgb(203, 215, 230);
}
</style>
