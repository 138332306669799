import { createApp, VueElement } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import Axios from './utils/request'
import accounting from 'accounting'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import en from "element-plus/es/locale/lang/en"

en.el.pagination.total = "合計：" + `{total}` + "件"
en.el.pagination.goto = ""
en.el.pagination.pagesize = "件/ページ"
en.el.pagination.pageClassifier = "ページへ"

const app = createApp(App)
app.config.globalProperties.$http = Axios

installElementPlus(app)
app.use(store).use(router, Axios).use(accounting).mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}