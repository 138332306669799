export function validatePhoneticName(rule, value, callback) {
    if (value == '' || value == undefined || value == null) {
        callback();
    } else {
        const reg = /^[\ｦ-ﾟ\u30a0-\u30ff]+$/
        if ((!reg.test(value)) && value != '') {
            callback(new Error('フリガナを入力してください'));
        } else {
            callback();
        }
    }
}

export function validateNumber(rule, value, callback) {
    if (value == '' || value == undefined || value == null) {
        callback();
    } else {
        const reg = /^[0-9]+$/
        if ((!reg.test(value)) && value != '') {
            callback(new Error('半角数字を入力してください'));
        } else {
            callback();
        }
    }
}

export function validateText(rule, value, callback) {
    if (value == '' || value == undefined || value == null) {
        callback();
    } else {
        const reg = /^[\A-Za-z\Ａ-Ｚａ-ｚ\ｦ-ﾟ\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/
        if ((!reg.test(value)) && value != '') {
            callback(new Error('半角英文字を入力してください'));
        } else {
            callback();
        }
    }
}