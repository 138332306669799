"use strict";

import { getToken } from '@/utils/auth';
import { sessionCache } from '@/plugins/cache';
import { tansParams } from '@/utils/format';
import axios from "axios";
import {
    ElMessage
} from 'element-plus';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
axios.defaults.baseURL = '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8; Access-Control-Allow-Headers";
axios.defaults.timeout = 10000;

let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    baseURL: 'apis',
    // timeout: 60 * 1000, // Timeout
    timeout: 10000,
    // withCredentials: true, // Check cross-site Access-Control

};

const _axios = axios.create(config);


// request interceptor
_axios.interceptors.request.use(config => {
    //  token
    const isToken = (config.headers || {}).isToken === false
    // 重複リクエスト
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    // getリクエストパラメータ
    if (config.method === 'get' && config.params) {
        let url = config.url + '?' + tansParams(config.params);
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
        const requestObj = {
            url: config.url,
            data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
            time: new Date().getTime()
        }
        const sessionObj = sessionCache.getJSON('sessionObj')
        if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
            sessionCache.setJSON('sessionObj', requestObj)
        } else {
            const s_url = sessionObj.url;                  // リクエストURL
            const s_data = sessionObj.data;                // リクエストデータ
            const s_time = sessionObj.time;                // リクエスト時間
            const interval = 1000;                         // リクエスト時間間隔(ms)
            if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
                const message = '重複リクエストをしないでください';
                console.warn(`[${s_url}]: ` + message)
                return Promise.reject(new Error(message))
            } else {
                sessionCache.setJSON('sessionObj', requestObj)
            }
        }
    }
    return config
}, error => {
    Promise.reject(error)
})

// Add a response interceptor
_axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response);
        }
    },
    error => {
        console.log('err' + error)
        let { message } = error;
        if (message == "Network Error") {
            message = "バックエンドリクエスト異常";
        } else if (message.includes("timeout")) {
            message = "システムリクエスト異常";
        } else if (message.includes("Request failed with status code")) {
            message = "システムリクエスト" + message.substr(message.length - 3) + "異常";
        }
        ElMessage({
            showClose: true,
            message: message,
            type: "error",
        });
        return Promise.reject(error)
    }
);

export default _axios;
