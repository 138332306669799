<template slot-scope="scope">
  <div class="common-layout">
    <el-header style="height: 80px; width: 100%">
      <div class="header" id="J_hd">
        <div class="searchBox">
          <div class="selectBox">
            <el-select
              style="width: 300px"
              clearable
              v-model="searchCondition.status"
              placeholder=" 契約ステータス を選択してください"
            >
              <el-option label="契約終了" value="0" />
              <el-option label="契約中" value="1" />
            </el-select>
          </div>
          <div class="inputBox">
            <el-input
              v-model="searchCondition.engineerName"
              placeholder=" 技術者氏名 を入力してください.."
              class="input-with-select"
              style="width: calc(100% - 130px)"
            >
            </el-input>
            <el-button type="primary" @click="getList">検索</el-button>
          </div>
        </div>
        <el-button class="insertBox" type="primary" @click="createFunction"
          >登録</el-button
        >
        <div class="loginbar">
          <span>契約担当者：</span>
          <span>{{ contractData.salesRepresentativeId }}</span>
        </div>
      </div>
    </el-header>

    <el-main style="padding: 0px; overflow-x: hidden; height: 750px">
      <div class="detailContainer">
        <div class="resultBar">
          <el-table
            :data="contractList.tableList"
            :row-style="{ height: '20px' }"
            stripe="true"
            style="width: 100%"
            max-height="700"
          >
            <el-table-column
              prop="engineerName"
              label="技術者氏名"
              width="auto"
              min-width="90"
            >
            </el-table-column>
            <el-table-column prop="orderId" label="受注先" width="auto">
            </el-table-column>
            <el-table-column
              prop="orderAmount"
              label="受注額"
              width="auto"
              :formatter="fmtMoney"
            >
            </el-table-column>
            <el-table-column
              prop="receiptSite"
              label="入金サイト"
              width="auto"
              min-width="90"
            >
            </el-table-column>
            <el-table-column prop="suppliersId" label="仕入先" width="auto">
            </el-table-column>
            <el-table-column
              prop="suppliersAmount"
              label="仕入額"
              width="auto"
              :formatter="fmtMoney"
            >
            </el-table-column>
            <el-table-column prop="paymentSite" label="支払サイト" width="auto">
            </el-table-column>
            <el-table-column
              prop="grossMarginAmount"
              label="粗利益額"
              width="auto"
              :formatter="fmtMoney"
            >
            </el-table-column>
            <el-table-column
              prop="settlementDate"
              label="清算時間"
              width="auto"
              :formatter="fmtime"
            >
            </el-table-column>
            <el-table-column
              prop="settlementDiscount"
              label="精算割"
              width="auto"
            >
            </el-table-column>
            <el-table-column
              prop="contractStartPeriod"
              label="契約開始期間"
              width="auto"
              :formatter="fmtime"
            >
            </el-table-column>
            <el-table-column
              prop="contractEndPeriod"
              label="契約終了期間"
              width="auto"
              :formatter="fmtime"
            >
            </el-table-column>
            <el-table-column prop="workLocation" label="作業場所" width="auto">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="200">
              <template #default="scope">
                <el-button size="small" @click="handleEdit(scope.row)"
                  >編集</el-button
                >
                <el-button
                  size="small"
                  @click="handleDelete(scope.row)"
                  type="danger"
                  >削除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="float: right">
            <el-pagination
              v-model:current-page="contractList.currentPage"
              v-model:page-size="contractList.sizes"
              :page-sizes="[10, 20, 30, 40]"
              :disabled="disabled"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="contractList.resultList.length"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </el-main>

    <el-dialog
      :title="detailTitle"
      width="1440px"
      v-model="dialogVisible"
      center
      draggable
      top="30px"
      :before-close="handleClose"
    >
      <el-container>
        <el-main
          style="
            height: calc(100vh - 180px);
            display: flex;
            justify-content: center;
          "
        >
          <div class="createInfo">
            <el-form
              ref="formRef"
              style="margin-top: 0px"
              :model="contractData"
              :rules="formRules"
              label-width="120px"
            >
              <el-row>
                <el-col :span="12">
                  <el-form-item label="技術者氏名" prop="engineerName">
                    <el-input v-model="contractData.engineerName" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="フリガナ" prop="phoneticName">
                    <el-input v-model="contractData.phoneticName" />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item label="受注先" prop="orderId">
                    <el-input v-model="contractData.orderId" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="受注額" prop="orderAmount">
                    <el-input
                      v-model="contractData.orderAmount"
                      @blur="calculate"
                    />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-form-item label="入金サイト" style="float: left">
                  <el-link href="/" target="_blank">{{
                    contractData.receiptSite
                  }}</el-link>
                </el-form-item></el-row
              >

              <el-row>
                <el-col :span="12">
                  <el-form-item label="仕入先" prop="suppliersId">
                    <el-input v-model="contractData.suppliersId" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="仕入額" prop="suppliersAmount">
                    <el-input
                      v-model="contractData.suppliersAmount"
                      @blur="calculate"
                    />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-form-item label="支払サイト" style="float: left">
                  <el-link href="/" target="_blank">{{
                    contractData.paymentSite
                  }}</el-link>
                </el-form-item>
              </el-row>

              <el-row>
                <el-form :model="form" label-width="0px">
                  <div class="card-div">
                    <el-card class="box-column-card" shadow="hover">
                      <template #header>
                        <div class="card-column-header">
                          <span>営業支援情報</span>
                        </div>
                      </template>

                      <el-row class="header-row">
                        <div class="action-header-column">
                          <span class="action-label">操作</span>
                          <el-button
                            round
                            type="primary"
                            size="mini"
                            class="action-button"
                            @click="addForm"
                            title="新增行"
                            >新規<i class="el-icon-plus el-icon-right"></i>
                          </el-button>
                        </div>
                        <div class="field-header-column" style="width: 350px">
                          <span>営業支援先</span>
                        </div>
                        <div class="field-header-column" style="width: 350px">
                          <span>支援費額</span>
                        </div>
                      </el-row>
                      <el-row class="header-row">
                        <template
                          v-for="(item, index) in addForms"
                          :key="index"
                        >
                          <div class="form-button-column">
                            <div class="button-column">
                              <el-button
                                class="button"
                                icon="el-icon-plus"
                                @click="addForm"
                                circle
                              />
                              <el-button
                                type="danger"
                                icon="el-icon-delete"
                                @click.prevent="removeForm(item)"
                                circle
                              />
                              <span class="row-number-span">
                                # {{ index + 1 }}</span
                              >
                            </div>
                          </div>
                          <div class="form-action-column">
                            <el-form-item :model="item">
                              <el-input
                                v-model="item.feedback"
                                style="width: 350px"
                              />
                            </el-form-item>
                          </div>
                          <div class="form-action-column">
                            <el-form-item :model="item">
                              <el-input
                                v-model="item.describe"
                                style="width: 350px"
                              />
                            </el-form-item>
                          </div>
                        </template>
                      </el-row>
                    </el-card>
                  </div>
                </el-form>
              </el-row>

              <el-form-item label="粗利益額">
                <el-input v-model="contractData.grossMarginAmount" readonly />
              </el-form-item>

              <el-row>
                <el-col :span="12">
                  <el-form-item label="清算時間" prop="settlementDate">
                    <el-col :span="11">
                      <el-date-picker
                        v-model="contractData.settlementDate"
                        type="date"
                        label="Pick a date"
                        placeholder="Pick a date"
                        style="width: 100%"
                      />
                    </el-col>
                    <el-col
                      class="text-center"
                      :span="1"
                      style="margin: 0 0.5rem"
                      >-</el-col
                    >
                    <el-col :span="11">
                      <el-time-picker
                        v-model="contractData.settlementDate"
                        label="Pick a time"
                        placeholder="Pick a time"
                        style="width: 100%"
                      />
                    </el-col>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="精算割" prop="settlementDiscount">
                    <el-input v-model="contractData.settlementDiscount" />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item label="契約期間" prop="contractPeriod">
                    <div class="block">
                      <el-date-picker
                        v-model="contractData.contractPeriod"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        @change="dateformat"
                      />
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="作業場所" prop="workLocation">
                    <el-input v-model="contractData.workLocation" />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item label="備考">
                <el-input v-model="contractData.remark" type="textarea" />
              </el-form-item>

              <div class="dialog-footer">
                <span>
                  <el-button type="primary" @click="onSubmit">登録</el-button>
                  <el-button @click="handleClose()">キャンセル</el-button>
                </span>
              </div>
            </el-form>
          </div>
        </el-main>

        <el-aside width="320px" v-if="asideShowFlg">
          <el-card class="box-card">
            <template #header>
              <div>
                <span>合計</span>
              </div>
            </template>

            <div style="margin-bottom: 18px; border-bottom: 1px solid #e0e5e7">
              <el-row>
                <el-col :span="12">
                  <div class="text item"><span>合計受注額</span></div>
                </el-col>
                <el-col :span="12">
                  <div class="text item">
                    <span>{{
                      formatMoney(contractData.orderAmountTotal)
                    }}</span>
                  </div>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <div class="text item"><span>平均受注額</span></div>
                </el-col>
                <el-col :span="12">
                  <div class="text item">
                    <span>{{ formatMoney(contractData.orderAmountAvg) }}</span>
                  </div>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <div class="text item"><span>合計仕入額</span></div>
                </el-col>
                <el-col :span="12">
                  <div class="text item">
                    <span>{{
                      formatMoney(contractData.suppliersAmountTotal)
                    }}</span>
                  </div>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <div class="text item"><span>平均仕入額</span></div>
                </el-col>
                <el-col :span="12">
                  <div class="text item">
                    <span>{{
                      formatMoney(contractData.suppliersAmountAvg)
                    }}</span>
                  </div>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <div class="text item"><span>粗利益額</span></div>
                </el-col>
                <el-col :span="12">
                  <div class="text item">
                    <span>{{
                      formatMoney(contractData.grossMarginMonthlyAmount)
                    }}</span>
                  </div>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <div class="text item"><span>平均粗利益額</span></div>
                </el-col>
                <el-col :span="12">
                  <div class="text item">
                    <span>{{
                      formatMoney(contractData.grossMarginMonthlyAmountAvg)
                    }}</span>
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-row>
              <el-col :span="12">
                <div class="text item"><span>想定年間売り上げ額</span></div>
              </el-col>
              <el-col :span="12">
                <div class="text item">
                  <span>{{
                    formatMoney(contractData.estimatedAnnualSaleAmount)
                  }}</span>
                </div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <div class="text item"><span>想定年間粗利益額</span></div>
              </el-col>
              <el-col :span="12">
                <div class="text item">
                  <span>{{
                    formatMoney(contractData.estimatedAnnualGrossMarginAmount)
                  }}</span>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-aside>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
import axios from "@/utils/request";
import {
  getList,
  saveContract,
  getContract,
  deleteContract,
} from "@/api/contract";
import { ElMessage, ElMessageBox } from "element-plus";
import { formatDate, formatMoney } from "@/utils/format";
import {
  validatePhoneticName,
  validateNumber,
  validateText,
} from "@/utils/validate";

export default {
  name: "contractLists",
  inject: ["reload"],

  data() {
    return {
      contractList: {
        resultList: [
          {
            contractId: "",
            salesRepresentativeId: "営業担当者A",
            engineerName: "",
            orderId: "",
            orderAmount: "",
            receiptSite: "",
            suppliersId: "",
            suppliersAmount: "",
            paymentSite: "",
            grossMarginAmount: "",
            settlementDate: "",
            settlementDiscount: "",
            contractStartPeriod: "",
            contractEndPeriod: "",
            workLocation: "",
            status: "",
            remark: "",
            calculationId: "",
            orderAmountTotal: null,
            orderAmountAvg: null,
            suppliersAmountTotal: null,
            suppliersAmountAvg: null,
            grossMarginMonthlyAmount: null,
            grossMarginMonthlyAmountAvg: null,
            estimatedAnnualSaleAmount: null,
            estimatedAnnualGrossMarginAmount: null,
          },
        ],
        tableList: [],
        currentPage: 1,
        sizes: 10,
      },
      shows: 0,
      contractData: {
        contractId: "",
        salesRepresentativeId: "営業担当A",
        engineerName: "",
        phoneticName: "",
        orderId: "",
        orderAmount: "",
        receiptSite: "default.html",
        suppliersId: "",
        suppliersAmount: "",
        paymentSite: "default.html",
        grossMarginAmount: "",
        settlementDate: "",
        settlementDiscount: "",
        contractStartPeriod: "",
        contractEndPeriod: "",
        workLocation: "",
        bussinessSupport: "",
        status: "",
        remark: "",
        calculationId: "",
        orderAmountTotal: null,
        orderAmountAvg: 500000,
        suppliersAmountTotal: null,
        suppliersAmountAvg: 350000,
        grossMarginMonthlyAmount: null,
        grossMarginMonthlyAmountAvg: 150000,
        estimatedAnnualSaleAmount: null,
        estimatedAnnualGrossMarginAmount: null,
        contractPeriod: [],
      },

      searchCondition: {
        engineerName: "",
        status: "",
      },
      addForms: [],
      dialogVisible: false,
      detailTitle: "契約登録",
      asideShowFlg: false,
      formRules: {
        engineerName: [
          {
            required: true,
            message: "必須項目が入力されていません",
            trigger: ["blur"],
          },
          {
            validator: validateText,
            trigger: ["blur"],
          },
        ],
        phoneticName: [
          {
            required: true,
            message: "必須項目が入力されていません",
            trigger: ["blur"],
          },
          {
            validator: validatePhoneticName,
            trigger: ["blur"],
          },
        ],
        orderId: [
          {
            required: true,
            message: "必須項目が入力されていません",
            trigger: ["blur"],
          },
          {
            validator: validateText,
            trigger: ["blur"],
          },
        ],
        orderAmount: [
          {
            required: true,
            message: "必須項目が入力されていません",
            trigger: ["blur"],
          },
          {
            validator: validateNumber,
            trigger: ["blur"],
          },
        ],
        suppliersId: [
          {
            required: true,
            message: "必須項目が入力されていません",
            trigger: ["blur"],
          },
          {
            validator: validateText,
            trigger: ["blur"],
          },
        ],
        settlementDate: [
          {
            required: true,
            message: "必須項目が入力されていません",
            trigger: ["blur"],
          },
        ],
        settlementDiscount: [
          {
            required: true,
            message: "必須項目が入力されていません",
            trigger: ["blur"],
          },
        ],
        contractPeriod: [
          {
            required: true,
            message: "必須項目が入力されていません",
            trigger: ["blur"],
          },
        ],
        workLocation: [
          {
            required: true,
            message: "必須項目が入力されていません",
            trigger: ["blur"],
          },
        ],
        suppliersAmount: [
          {
            required: true,
            message: "必須項目が入力されていません",
            trigger: ["blur"],
          },
          {
            validator: validateNumber,
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  filters: {},
  computed: {
    formatMoney() {
      return (number) => formatMoney(number);
    },
  },

  created() {
    this.getList();
  },
  methods: {
    fmtime(row, column) {
      return formatDate(row[column.property]);
    },
    fmtMoney(row, column) {
      return formatMoney(row[column.property]);
    },
    reset() {
      this.detailTitle = this.$options.data().detailTitle;
      this.addForms = this.$options.data().addForms;
      this.contractList.currentPage = 1;
      this.contractData.contractPeriod =
        this.$options.data().contractData.contractPeriod;
      this.contractData = this.$options.data().contractData;
    },
    addForm() {
      if (this.addForms.length < 15) {
        this.addForms.push({
          feedback: "",
          describe: "",
        });
      }
    },
    removeForm(item) {
      ElMessageBox.confirm(
        "該当行を削除しようとしています、よろしいですか?",
        "お知らせ",
        {
          confirmButtonText: "確認",
          cancelButtonText: "キャンセル",
          type: "info",
        }
      ).then(() => {
        const index = this.addForms.indexOf(item);
        if (index !== -1) {
          this.addForms.splice(index, 1);
        }
      });
    },

    //契約情報リスト取得
    getList() {
      const _this = this;

      getList(this.searchCondition).then(function (resp) {
        _this.contractList.resultList = resp.data;
        _this.contractList.tableList = _this.contractList.resultList.slice(
          0,
          _this.contractList.sizes
        );
      });
    },

    createFunction() {
      this.reset();
      this.asideShowFlg = false;
      this.dialogVisible = true;
    },

    //契約情報の新規&編集
    onSubmit() {
      ElMessageBox.confirm(
        "契約管理情報を登録/保存しようとしています. よろしいですか?",
        "お知らせ",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "info",
        }
      ).then(() => {
        this.$refs["formRef"].validate((valid) => {
          if (valid) {
            this.contractData.bussinessSupport = JSON.stringify(this.addForms);

            var message = "";
            if (this.contractData.contractId != "") {
              message = " 契約管理情報 が正常に編集されました";
            } else {
              message = " 契約管理情報 が正常に保存されました";
            }

            saveContract(this.contractData)
              .then(function (resp) {
                ElMessage({
                  showClose: true,
                  message: message,
                  type: "success",
                });
                reset();
              })
              .catch(function (err) {
                console.log(err);
              });
            this.dialogVisible = false;
            this.getList();
          } else {
            console.log("error submit!");
            return false;
          }
        });
      });
    },

    //契約期間フォーマット
    dateformat(picker) {
      if (picker != null) {
        this.contractData.contractStartPeriod = picker[0];
        this.contractData.contractEndPeriod = picker[1];
      } else {
        this.contractData.contractStartPeriod = "";
        this.contractData.contractEndPeriod = "";
      }
      this.calculate();
    },

    //合計金額フォーマット
    calculate() {
      // 受注額
      let orderAmount = this.contractData.orderAmount;
      // 仕入額
      let suppliersAmount = this.contractData.suppliersAmount;
      // 粗利益額
      let grossMarginAmount = orderAmount - suppliersAmount;

      if (orderAmount.length != 0 && suppliersAmount.length != 0) {
        let months = parseInt(
          Math.ceil(
            Math.abs(
              this.contractData.contractEndPeriod -
                this.contractData.contractStartPeriod
            ) /
              (24 * 3600 * 1000 * 30)
          )
        );
        // 粗利益額
        this.contractData.grossMarginAmount = grossMarginAmount;
        if (
          this.contractData.contractStartPeriod.toString().length != 0 &&
          this.contractData.contractEndPeriod.toString().length != 0
        ) {
          // 合計受注額
          this.contractData.orderAmountTotal = orderAmount * months;
          // 合計仕入額
          this.contractData.suppliersAmountTotal = suppliersAmount * months;
          // 合計粗利益額
          this.contractData.grossMarginMonthlyAmount =
            this.contractData.orderAmountTotal -
            this.contractData.suppliersAmountTotal;
          // 想定年間売り上げ額
          this.contractData.estimatedAnnualSaleAmount = orderAmount * 12;
          // 想定年間粗利益額
          this.contractData.estimatedAnnualGrossMarginAmount =
            grossMarginAmount * 12;
        }
      }
    },

    handleSizeChange(val) {
      this.contractList.sizes = val;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.contractList.currentPage = val;
      var size = this.contractList.sizes;
      console.log(size);
      this.contractList.tableList = this.contractList.resultList.slice(
        val * size - size,
        val * size
      );
    },

    handleClose() {
      ElMessageBox.confirm(
        "契約情報を保存せずに該当ダイアログを閉じようとしています、よろしいですか?",
        {
          confirmButtonText: "確認",
          cancelButtonText: "キャンセル",
          type: "warning",
        }
      )
        .then(() => {
          this.reset();
          this.$refs["formRef"].clearValidate();
          this.dialogVisible = false;
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    //契約番号より契約情報を取得
    handleEdit(row) {
      const _this = this;

      const contractId = row.contractId;

      getContract(contractId)
        .then(function (resp) {
          _this.contractData = resp.data;

          if (_this.contractData.bussinessSupport != null) {
            _this.addForms = JSON.parse(_this.contractData.bussinessSupport);
          } else {
            _this.addForms = _this.$options.data.call(_this).addForms;
          }

          let beginTime = new Date(resp.data.contractStartPeriod);
          let endTime = new Date(resp.data.contractEndPeriod);

          _this.contractData.contractPeriod = [beginTime, endTime];
          _this.contractData.contractStartPeriod = beginTime;
          _this.contractData.contractEndPeriod = endTime;

          _this.dialogVisible = true;
          _this.asideShowFlg = true;
          _this.detailTitle = "契約詳細";
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    handleDelete(row) {
      ElMessageBox.confirm(
        "契約管理情報を削除しようとしています、よろしいですか?",
        "お知らせ",
        {
          confirmButtonText: "OK",
          cancelButtonText: "キャンセル",
          type: "warning",
        }
      )
        .then(() => {
          const contractId = row.contractId;

          deleteContract(contractId)
            .then(function () {
              ElMessage({
                type: "success",
                message: "契約管理情報を正常に削除しました",
              });
              reset();
            })
            .catch(function (err) {
              console.log(err);
            });

          this.reload();
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
  },
};
</script>

<style scoped>
@import "@/style/contract.css";
</style>