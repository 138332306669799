import request from '@/utils/request'

export function getList(data) {
    return request({
        url: '/getContractByName',
        method: 'post',
        data: data
    })
}

export function saveContract(data) {
    return request({
        url: '/saveContract',
        method: 'post',
        data: data
    })
}

export function getContract(contractId) {
    return request({
        url: '/getContractById/' + contractId,
        method: 'get',
    })
}

export function deleteContract(contractId) {
    return request({
        url: '/deleteContract/' + contractId,
        method: 'delete',
    })
}

